import { customerRatingsConfig } from './product/customerRatings';
import { installmentCalculatorConfig } from './product/installmentCalculator';
import { orderConfig } from './order/order';
import { productConfig } from './product/product';
import { Environment } from './types';
import type { Locale, Config } from './types';

// this export is bad but some implementations imported types from this default file
// these imports has to be moved to types.ts file in future

// GeoCountryCode, Locale, Config, Language are exported by the generated index file -> should this imported from that file instead?
export type {
  CmsFormsFieldConfig,
  CmsFormsFieldOptionsConfig,
  Flags,
  AlternativesShops,
  LocalizedPath,
  ChatBotConfig,
  TestPanelApp,
  TestPanelSystem,
  PersonalizationSegmentSource,
  GeoCountryCode,
  Locale,
  Config,
  Language,
} from './types';

export const localizedPathnameCacheKey = 'localizedPathname';

export const environment =
  process.env.NEXT_PUBLIC_ENVIRONMENT === 'staging' ? Environment.STAGING : Environment.PRODUCTION;

const tenantId = 'default';

const config: Config = {
  host: { name: 'Default Host', domain: '/' },
  i18n: {
    defaultLocale: 'de-DE',
    languages: ['de'],
    locales: ['de-DE'],
  },
  clientId: 'default',
  tenantId,
  styleVariant: 'default',
  company: {
    name: 'default',
    seoTitleName: 'default',
  },
  timeZone: 'Europe/Berlin',
  captcha: {
    recaptchaSessionToken: '',
  },
  assets: {
    cmsImage: {
      imageQuality: 75,
      imageOptimizationEnabled: true,
      uriTransformSource: /^cloud-storage-(.*?):\/\//,
      uriTransformEndpoint: `https://storage.googleapis.com/drupal-images-${tenantId}/$1/`,
    },
    assetServerHost: '/',
  },
  apiEndpoints: {
    search: '/api/search/searchv2',
    searchFilter: '/api/search/filter',
    searchSeo: '/api/search/seo',
    suggest: '/api/search/suggest',
    historyGet: '/api/search/searchhistory/',
    historyApiNew: '/api/search/searchhistory/v1',
    historyDelete: '/api/search/searchhistory/delete',
    historyDeleteAll: '/api/search/searchhistory/deleteall',
    historyDeleteAllNew: '/api/search/searchhistory/v1/all',
    historyJoinWithFi: '/api/search/searchhistory/v1/join-with-fi',
    customer: '/api/customer/v1',
  },
  middleware: {
    cmsRewrites: {
      exact: ['/', '/storefront', '/fr'],
      prefixes: [
        '/service-hilfe',
        '/aide-service',
        '/lp',
        '/magazin',
        '/magazine',
        '/node',
        '/klantenservice',
        '/inspiratie',
        '/special',
      ],
    },
  },
  dynamicYieldEnabled: true,
  insiderPushNotifications: {
    enabled: false,
  },
  hermesShopFinder: {
    url: 'https://paketshop.myhermes.de/int/hpsf.html',
    rParam: 'be22763e-f7e9-4',
  },
  product: productConfig,
  installmentCalculator: installmentCalculatorConfig,
  header: {
    hasNewNavigation: true,
    hasSmallerFontSize: false,
    desktopNavigationMode: 'hover',
  },
  usp: {
    isEnabled: true,
  },
  search: {
    personalizationSegmentSource: 'unito',
    adServer: {
      mode: 'disabled',
      mkzBlacklist: [957],
    },
    hideTelekomIcon: false,
    isInspiringSearchEnabled: false,
    inspiringSearchDataBucket: 'https://storage.googleapis.com/emp-search-inspiring-search',
  },
  order: orderConfig,
  navigation: {
    firstLevel: {
      color: 'text.dark',
      border: 'primary.main',
    },
    loadSliced: false,
  },
  enableCustomerNrLogin: false,
  headerSlots: {
    localeSwitcher: false,
    payback: false,
    warehouse: false,
    joe: false,
  },
  lifeSupportForBasicallyDeadTenant: false,
  headerLinks: {
    'de-DE': {
      serviceLink: '/service-hilfe',
    },
    'de-AT': {
      serviceLink: '/service-hilfe',
    },
    'de-CH': {
      serviceLink: '/service-hilfe',
    },
    'fr-CH': {
      serviceLink: '/fr/aide-service',
    },
    'nl-NL': {
      serviceLink: '/klantenservice',
    },
  },
  staticContent: {
    fetchSettings: {
      timeout: 5000,
    },
    apiEndpoints: {
      jsonApi: `/<locale>/<page>?_format=webapp_json&flat`,
      webappJson: `https://cms.${tenantId}.empirie.<envTld>/<locale>/<path>?_format=webapp_json&flat`,
      bucket: `https://storage.googleapis.com/drupal-pages/${environment}<subfolder>/${tenantId}/<locale>/<identifier>.json`,
    },
    ssg: {
      revalidateInterval: 15,
      prerenderAtBuildTime: [],
    },
  },
  footer: {
    fetchSettings: {
      timeout: 5000,
    },
    apiEndpoints: {
      jsonApi: `/<locale>/<page>?_format=webapp_json&flat`,
      bucket: `https://storage.googleapis.com/drupal-pages/${environment}<subfolder>/${tenantId}/<locale>/<identifier>.json`,
      deDupingInterval: 3000,
    },
    ssg: {
      revalidateInterval: 15,
      prerenderAtBuildTime: [],
    },
  },
  forms: {
    useOptimizely: true,
    apiAgnitasUrl: {
      enabled: true,
      environmentRelated: true,
      values: {
        url: '/api/inspire/agnitas/v0',
        useDOI: true,
        sendAdditionalSubscribe: false,
      },
    },
    contact: {
      selectOptionsSubject: [
        { label: 'forms.type.subject.option.order', value: 'order' },
        { label: 'forms.type.subject.option.datasecurity', value: 'datasecurity' },
        { label: 'forms.type.subject.option.web', value: 'web' },
        { label: 'forms.type.subject.option.account', value: 'account' },
        { label: 'forms.type.subject.option.personalData', value: 'personalData' },
      ],
      enableFileUpload: true,
    },
    newsletterUnsubscribe: {
      useDedicatedSubmitButtonTitleFor: undefined,
      fields: [
        {
          id: 'newsletterrrrrr',
          formType: 'radio',
          options: [
            {
              id: 'frq-daily',
              value: 'frequency|0',
              messageKey: 'forms.newsletter.unsubscribe.type.frequency.option.daily',
            },
            {
              id: 'frq-weekly',
              value: 'frequency|1',
              messageKey: 'forms.newsletter.unsubscribe.type.frequency.option.weekly',
            },
            {
              id: 'frq-bi-weekly',
              value: 'frequency|2',
              messageKey: 'forms.newsletter.unsubscribe.type.frequency.option.biweekly',
            },
            {
              id: 'frq-monthly',
              value: 'frequency|3',
              messageKey: 'forms.newsletter.unsubscribe.type.frequency.option.monthly',
            },
            {
              id: 'unsubscribe',
              value: 'unsubscribe|99',
              messageKey: 'forms.newsletter.unsubscribe.type.unsubscribe',
            },
            {
              id: 'pause',
              value: 'pause|-100',
              messageKey: 'forms.newsletter.unsubscribe.type.pause',
            },
          ],
          additionalForm: {
            id: 'pause',
            formType: 'select',
            showOnlyWhenWhatSelected: 'pause',
            messageKeyPlaceholder: 'forms.newsletter.unsubscribe.type.pause.label',
            options: [
              {
                id: 'pause-7',
                value: '7',
                messageKey: 'forms.newsletter.unsubscribe.type.pause.option.7',
              },
              {
                id: 'pause-14',
                value: '14',
                messageKey: 'forms.newsletter.unsubscribe.type.pause.option.14',
              },
              {
                id: 'pause-21',
                value: '21',
                messageKey: 'forms.newsletter.unsubscribe.type.pause.option.21',
              },
              {
                id: 'pause-30',
                value: '30',
                messageKey: 'forms.newsletter.unsubscribe.type.pause.option.30',
              },
            ],
          },
        },
      ],
    },
    newsletterSubscribe: {
      de: {
        unsubscribeLink: 'https://default/de/link/to/unsubscribe',
        voucherLink: 'https://default/de/link/to/voucher',
      },
      fr: {
        unsubscribeLink: 'https://default/fr/link/to/unsubscribe',
        voucherLink: 'https://default/fr/link/to/voucher',
      },
      en: {
        unsubscribeLink: 'https://default/en/link/to/unsubscribe',
        voucherLink: 'https://default/en/link/to/voucher',
      },
      nl: {
        unsubscribeLink: 'https://default/en/link/to/unsubscribe',
        voucherLink: 'https://default/en/link/to/voucher',
      },
    },
    newsletterSheet: {
      enabled: true,
    },
    validation: {
      rules: {
        customerId: {
          regex: /^[0-9]*$/,
          errorMessage: {
            id: 'forms.error.numbersonly',
            defaultMessage: 'Nur Zahlen sind erlaubt',
          },
        },
        postalCode: {
          regex: /^[0-9]*$/,
          errorMessage: {
            id: 'forms.error.numbersonly',
            defaultMessage: 'Nur Zahlen sind erlaubt',
          },
        },
        fileUpload: {
          fileLimit: 10,
          maxFileSizeMb: 5,
          maxFileSizeSummaryMb: 5,
          acceptedFormats: 'image/*,.heic,.heif',
          regexAcceptedUploadFormats: /\.(jpe?g|png|gif|webp|bmp|heic|heif)$/i,
          // used for image preview
          // please list supported image file types only to prevent broken preview:
          // https://developer.mozilla.org/en-US/docs/Web/Media/Formats/Image_types
          regexToCheckImageFormats: /\.(jpe?g|apng|png|gif|webp|bmp|tiff?|svg|ico|avif)$/i,
          indicators: {
            showFileSize: true,
            showFileSizeSummary: true,
          },
        },
      },
    },
  },
  voucher: {
    apiEndpoints: {
      api: '/api/voucher',
      bucket: `https://storage.googleapis.com/promotion-data/${tenantId}/<locale>/<identifier>.json`,
    },
  },
  banner: {
    apiEndpoints: {
      bucket: `https://storage.googleapis.com/drupal-surveys/${environment}/${tenantId}/<locale>/<identifier>.json`,
    },
    ssg: {
      revalidateInterval: 15,
      prerenderAtBuildTime: [],
    },
  },
  nlSheetTexts: {
    apiEndpoints: {
      bucket: `https://storage.googleapis.com/drupal-contentsnippet/${environment}/${tenantId}/<locale>/nl-sheet.json`,
    },
    ssg: {
      revalidateInterval: 15,
      prerenderAtBuildTime: [],
    },
  },
  serviceLayer: {
    apiEndpoints: {
      api: '/api/servicelayer',
      bucket: `https://storage.googleapis.com/drupal-contentsnippet/${environment}/${tenantId}/<locale>/<identifier>.json`,
      bucketPages: `https://storage.googleapis.com/drupal-pages/${environment}/${tenantId}/<locale>/<identifier>.json`,
    },
    ssg: {
      revalidateInterval: 15,
      prerenderAtBuildTime: [],
    },
  },
  seoContent: {
    apiEndpoints: {
      bucket: `https://storage.googleapis.com/inspire-seofiles/<locale>_${tenantId}-<category>.json`,
    },
  },
  survey: {
    apiEndpoints: {
      bucket: `https://storage.googleapis.com/drupal-surveys/${environment}/${tenantId}/<locale>/<identifier>.json`,
    },
  },
  chatBot: {
    enabled: false,
    key: '',
    includePaths: ['/'],
    excludePaths: [],
    visibility: {
      main: true,
      button: true,
      notifications: false,
    },
  },
  shoppingApp: {
    pathsToRenderChildrenOnly: ['/servicelayer/'],
  },
  sovendus: {
    enabled: true,
  },
  promotionBanner: {
    apiEndpoints: {
      jsonApi: `/<locale>/<page>?_format=webapp_json&flat`,
      bucketEmptyPromotions: `https://storage.googleapis.com/drupal-contentsnippet/${environment}/${tenantId}/<language>/empty-promotion.json`,
      bucketPromotion: `https://storage.googleapis.com/promotion-data/${tenantId}/<language>/<code>.json`,
      bucketContentSnippet: `https://storage.googleapis.com/drupal-contentsnippet/${environment}/<language>/${tenantId}/<identifier>.json`,
    },
  },
  video: {
    video_youtube: {
      url: 'https://www.youtube-nocookie.com/embed/<id>?autoplay=<autoplay>&playsinline=1&rel=0',
      provider: 'YouTube',
      name: 'YouTube',
    },
  },
  recommendations: {
    apiEndpoint: '/api/inform/graphql',
    provider: 'prudsys',
  },
  searchResultUserFeedback: {
    appUrl:
      'https://sw01.rogsurvey.de/html/survey.htm?n=empiriecom/Suchefeedback_<buttonType>&ref=<sessionId>&ref=<searchQuery>',
    enabled: false,
  },
  appBanner: { enabled: true, appUrl: '' },
  tracking: {
    soastaId: '',
    tagmanagerId: '',
    pinterestRichPinVerificationID: '',
    tagmanagerDomain: undefined, // explicitly undefined here for default values to work in TrackingProvider
    tagmanagerSrc: undefined, // explicitly undefined here for overwrite check in TrackingProvider
    schemaOrgOrganisation: {
      'de-DE': {
        addressCountry: 'DE',
        addressRegion: 'BY',
        addressLocality: 'Burgkunstadt',
        alternateName: [],
        areaServed: 'DE',
        contactEmail: 'service@empirie.dev',
        contactUrl: 'https://www.empirie.dev/kontakt',
        description: 'Ein Emma Demo-Shop',
        email: 'service@empirie.dev',
        hoursAvailable: [],
        legalName: 'empiriecom GmbH & Co. KG',
        logo: `/assets/logos/${tenantId}/logo.svg`,
        name: 'EMMA Shop',
        postalCode: '96224',
        privacyNoticePath: '/service-hilfe/ueber-uns/datenschutz/datenschutzhinweis',
        productSupported: '',
        sameAs: [],
        streetAddress: 'Bahnhofstraße 10',
        telephone: '',
        url: 'https://www.empirie.dev',
        potentialAction: {
          target: 'https://empirie.dev/s/',
        },
      },
      'de-AT': {
        addressCountry: 'DE',
        addressRegion: 'BY',
        addressLocality: 'Burgkunstadt',
        alternateName: [],
        areaServed: 'DE',
        contactEmail: 'service@empirie.dev',
        contactUrl: 'https://www.empirie.dev/kontakt',
        description: 'Ein Emma Demo-Shop',
        email: 'service@empirie.dev',
        hoursAvailable: [],
        legalName: 'empiriecom GmbH & Co. KG',
        logo: `/assets/logos/${tenantId}/logo.svg`,
        name: 'EMMA Shop',
        postalCode: '96224',
        privacyNoticePath: '/service-hilfe/ueber-uns/datenschutz/datenschutzhinweis',
        productSupported: '',
        sameAs: [],
        streetAddress: 'Bahnhofstraße 10',
        telephone: '',
        url: 'https://www.empirie.dev',
        potentialAction: {
          target: 'https://empirie.dev/s/',
        },
      },
      'de-CH': {
        addressCountry: 'DE',
        addressRegion: 'BY',
        addressLocality: 'Burgkunstadt',
        alternateName: [],
        areaServed: 'DE',
        contactEmail: 'service@empirie.dev',
        contactUrl: 'https://www.empirie.dev/kontakt',
        description: 'Ein Emma Demo-Shop',
        email: 'service@empirie.dev',
        hoursAvailable: [],
        legalName: 'empiriecom GmbH & Co. KG',
        logo: `/assets/logos/${tenantId}/logo.svg`,
        name: 'EMMA Shop',
        postalCode: '96224',
        privacyNoticePath: '/service-hilfe/ueber-uns/datenschutz/datenschutzhinweis',
        productSupported: '',
        sameAs: [],
        streetAddress: 'Bahnhofstraße 10',
        telephone: '',
        url: 'https://www.empirie.dev',
        potentialAction: {
          target: 'https://empirie.dev/s/',
        },
      },
      'fr-CH': {
        addressCountry: 'DE',
        addressRegion: 'BY',
        addressLocality: 'Burgkunstadt',
        alternateName: [],
        areaServed: 'DE',
        contactEmail: 'service@empirie.dev',
        contactUrl: 'https://www.empirie.dev/kontakt',
        description: 'Ein Emma Demo-Shop',
        email: 'service@empirie.dev',
        hoursAvailable: [],
        legalName: 'empiriecom GmbH & Co. KG',
        logo: `/assets/logos/${tenantId}/logo.svg`,
        name: 'EMMA Shop',
        postalCode: '96224',
        privacyNoticePath: '/service-hilfe/ueber-uns/datenschutz/datenschutzhinweis',
        productSupported: '',
        sameAs: [],
        streetAddress: 'Bahnhofstraße 10',
        telephone: '',
        url: 'https://www.empirie.dev',
        potentialAction: {
          target: 'https://empirie.dev/s/',
        },
      },
      'nl-NL': {
        addressCountry: 'DE',
        addressRegion: 'BY',
        addressLocality: 'Burgkunstadt',
        alternateName: [],
        areaServed: 'DE',
        contactEmail: 'service@empirie.dev',
        contactUrl: 'https://www.empirie.dev/kontakt',
        description: 'Ein Emma Demo-Shop',
        email: 'service@empirie.dev',
        hoursAvailable: [],
        legalName: 'empiriecom GmbH & Co. KG',
        logo: `/assets/logos/${tenantId}/logo.svg`,
        name: 'EMMA Shop',
        postalCode: '96224',
        privacyNoticePath: '/service-hilfe/ueber-uns/datenschutz/datenschutzhinweis',
        productSupported: '',
        sameAs: [],
        streetAddress: 'Bahnhofstraße 10',
        telephone: '',
        url: 'https://www.empirie.dev',
        potentialAction: {
          target: 'https://empirie.dev/s/',
        },
      },
    },
  },
  abTesting: {
    serverSideEnrollment: {
      activeTestIds: [],
    },
  },
  testPanel: {
    apps: [
      {
        appLabel: 'Shopping',
        appName: 'app-shopping',
        defaultBranch: 'master',
        projectId: '35259303',
        featureCookieName: 'feature-app-shopping',
      },
      {
        appLabel: 'Order',
        appName: 'app-order',
        defaultBranch: 'master',
        projectId: '35259303',
        featureCookieName: 'feature-app-order',
      },
      {
        appLabel: 'Legacy Header',
        appName: 'app-header',
        defaultBranch: 'master',
        projectId: '35259303',
        featureCookieName: 'feature-app-header',
      },
    ],
    backends: [
      {
        appLabel: 'Customer',
        appName: 'backend-mybuy-customer',
        defaultBranch: 'master',
        projectId: '19850631',
        featureCookieName: 'feature-backend-customer',
        hasPathQuerySyntax: true,
      },
      {
        appLabel: 'Wishlist',
        appName: 'backend-mybuy-wishlist',
        defaultBranch: 'master',
        projectId: '20841174',
        featureCookieName: 'feature-backend-wishlist',
        hasPathQuerySyntax: true,
      },
      {
        appLabel: 'Checkout',
        appName: 'backend-mybuy-checkout',
        defaultBranch: 'master',
        projectId: '21918200',
        featureCookieName: 'feature-backend-checkout',
        hasPathQuerySyntax: true,
      },
      {
        appLabel: 'Paylink',
        appName: 'backend-mybuy-paylink',
        defaultBranch: 'master',
        projectId: '24424921',
        featureCookieName: 'feature-backend-paylink',
        hasPathQuerySyntax: true,
      },
      {
        appLabel: 'Joe',
        appName: 'cloud-service-order-joe',
        defaultBranch: 'master',
        projectId: '21571033',
        featureCookieName: 'feature-joe-service',
      },
    ],
  },
  headerInformation: {
    de: {
      description: 'default description',
      title: 'default title',
      canonical: 'https://default.de',
      robots: 'noodp,index,follow',
    },
    fr: {
      description: 'default description',
      title: 'default title',
      canonical: 'https://default.fr',
      robots: 'noodp,index,follow',
    },
  },
  customerRatings: customerRatingsConfig,
  customerCentricFeedback: {
    enabled: true,
    tenantId: '1900',
    surveyIdStorefront: '3__e4c7114417e509881f280c5adb7f4c93',
    surveyIdSearch: '3__f371392e3fb65c250deca2350c28124d',
    surveyIdDetailview: '3__56263b79205164c26026b71a8a2c4423',
  },
  dynamicYieldTracking: {
    key: '',
    endpoint: 'https://dy-api.eu/v2/collect/user/event',
    endpointClientSide: '/api/dynamicyield/<dyType>/<dyId>/<dySession>/<hashedEmail>',
  },
  officeTimes: {
    mon: '08:00-21:00',
    tue: '08:00-21:00',
    wed: '08:00-21:00',
    thu: '08:00-21:00',
    fri: '08:00-21:00',
    sat: '08:00-12:00',
  },
  geoCountryLayer: {
    enabled: false,
    img: {
      path: 'https://storage.googleapis.com/emp-common-libs/assets/geo-country-layer/<tenant>/360x360.jpg',
      alt: '',
      title: '',
    },
  },
  redirectOverlay: {
    enabled: false,
    redirectParam: '',
    redirectLp: {
      de: '',
    },
  },
  lounge: {},
  bookings: {
    overview: {
      hasExplicitMaturities: false,
      showOpenPayment: 'minimal-payment',
    },
  },
  priceFormat: 'Empiriecom',
};

export const validLanguages = ['de', 'en', 'fr', 'it', 'es', 'nl', 'pl', 'pt', 'da', 'sv'];

export const getFullLocaleFromConfig = (
  localeOrLanguage: string | undefined,
  configObject: Config,
): Locale => {
  const result = localeOrLanguage
    ? configObject.i18n.locales.find((locale) => locale.startsWith(localeOrLanguage))
    : undefined;
  return result || configObject.i18n.defaultLocale;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const customArrayMerge = (_target: any[], source: any[], _options: any) => source;

/** DON'T USE. Import from '@packages/config' for tenant specific version!
 *  This function is a dummy for the default tenant.
 */
export const getFullLocale = (localeOrLanguage: string | undefined) =>
  getFullLocaleFromConfig(localeOrLanguage, config);

// eslint-disable-next-line import/no-default-export
export default config;

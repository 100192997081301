import type { ExternalScriptType } from './types';
import { registerComponents } from '../ComponentRecognizer/componentRegistry';
import { ComponentTypes } from '../../../interfaces';
import { HappyContest } from './scripts/HappyContest';
import { Loadbee } from './scripts/Loadbee';
import { Liveshopping } from './scripts/Liveshopping';
import { Movex } from './scripts/Movex';
import { Sovendus } from './scripts/Sovendus';
import { KuecheCo } from './scripts/KuecheCo';
import { SmartAssistant } from './scripts/SmartAssistant';
import { TrustedShops } from './scripts/TrustedShops';
import { Userwerk } from './scripts/Userwerk';
import { Zoovu } from './scripts/Zoovu';
import { Flixmedia } from './scripts/Flixmedia';

/**
 * This component creates "div-replacement" containers for different providers.
 * Into these placeholders external content will be placed using GTM with push into the data layer.
 *
 * @param data Object contains data
 * @returns JSX.Element to be placed in PageLayout
 */
export const ExternalScript = ({ data }: { data: ExternalScriptType }) => {
  if (data.type === 'external_script_happycontest') {
    return <HappyContest id={data.field_id} src={data.field_src} />;
  }

  if (data.type === 'external_script_loadbee') {
    return (
      <Loadbee
        externalApiKey={data.field_api_key}
        brand={data.field_shop_id}
        urlProducts={data.field_url_products}
      />
    );
  }

  if (data.type === 'video_liveshopping') {
    return <Liveshopping showId={data.field_video_id} />;
  }

  if (data.type === ComponentTypes.Movex) {
    return <Movex mediaLibraryId={data.field_media_library_id} />;
  }

  if (data.type === 'externalscript') {
    const { field_script_type: scriptType, field_api_key: externalApiKey } = data;

    if (scriptType === 'sovendus') {
      return <Sovendus />;
    }
    if (externalApiKey) {
      switch (scriptType) {
        case 'kueche_co':
          return <KuecheCo journey={externalApiKey} />;
        case 'smartAssistant':
          return <SmartAssistant advisorCode={externalApiKey} />;
        case 'trustedShops':
          return <TrustedShops externalApiKey={externalApiKey} />;
        case 'userwerk':
          return <Userwerk shopTag={externalApiKey} />;
        case 'zoovu':
          return <Zoovu advisorId={externalApiKey} />;
        case 'zoovu_url':
          return <Zoovu zoovuURL={externalApiKey} />;
        case 'flixmedia':
          return <Flixmedia descriptor={externalApiKey} />;
        default:
          break;
      }
    }
  }

  return <div data-testid="no matching script type" />;
};

// Needed to run this component in Storybook and Jest tests. Register the component in a global componentRegistry object
registerComponents({ [ComponentTypes.Liveshopping]: ExternalScript });
registerComponents({ [ComponentTypes.ExternalScript]: ExternalScript });
registerComponents({ [ComponentTypes.ExternalScriptHappyContest]: ExternalScript });
registerComponents({ [ComponentTypes.ExternalScriptLoadbee]: ExternalScript });
registerComponents({ [ComponentTypes.Movex]: ExternalScript });
